import React from 'react';
import { Route } from 'react-router-dom';
import CallbackPage from './components/callback';
import SignupPage from './components/signup/signup';
import SignupSuccessPage from './components/signup/signupSuccess';
import AuthenticationErrorPage from './components/AuthenticationError'

export default [
  <Route
    key="callback"
    path="/callback"
    render={props => {
      return <CallbackPage {...props} />;
    }}
  />,
  <Route
    key="authentication-error"
    path="/authentication-error"
    noLayout
    render={props => {
      return <AuthenticationErrorPage {...props} />;
    }}
  />,
  <Route
    key="signup"
    path="/signup"
    noLayout
    render={props => {
      return <SignupPage {...props} />;
    }}
  />,
  <Route
    key="signupSuccess"
    path="/signupSuccess"
    noLayout
    render={props => {
      return <SignupSuccessPage {...props} />;
    }}
  />,
];
