// taken from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NPI_LENGTH = 10;
const PHONE_NUMBER_LENGTH = 10;
const TIN_LENGTH = 9;
const MIN_NAME_LENGTH = 1;

const isNumeric = value => /^\d+$/.test(value);
const countNumericChars = value => value.replace(/\D/g, '').length;

export const required = (message = 'Required') => value => (value ? undefined : message);
export const isOptional = validate => value => {
  return value && `${value}`.length ? validate(value) : undefined;
};
export const isNpi = (message = 'NPI must be a ten digit number') => value => {
  return isNumeric(value) && value.length === NPI_LENGTH ? undefined : message;
};
export const isPhoneNumber = (
  message = 'Must be a valid phone number with a "000-000-0000" format'
) => value => {
  return value && countNumericChars(value) === PHONE_NUMBER_LENGTH ? undefined : message;
};

export const isEmail = (message = 'Must be a valid email address') => value => {
  return EMAIL_REGEX.test(String(value).toLowerCase()) ? undefined : message;
};

export const isName = (message = 'Must be at least one character') => value => {
  return value && value.length >= MIN_NAME_LENGTH ? undefined : message;
};

export const lengthIsLessThan = (
  maxLength,
  validation,
  message = `Must be less than or equal to ${maxLength} characters`
) => value => {
  if (value && value.length <= maxLength) {
    return validation ? validation(value) : undefined;
  }
  return message;
};

export const isTin = (message = 'TIN must be numberic with a "000-00-0000" format') => value => {
  return value && countNumericChars(value) === TIN_LENGTH ? undefined : message;
};
export const isAddress = (message = 'Must be a valid address') => value => required(message)(value);
