import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';

const deletedAtFilter = {
  field: "deletedAt",
  operator: CondOperator.IS_NULL,
  value: true,
};
const softDeletedResources = ['appointments'];
const isSearchQuery = (query) => !!query.searchQuery;
const formatUrl = (url, resource, query) => `${url}/${resource}?${query}`;

const composeFilter = paramsFilter => {
  if (
    paramsFilter === "" ||
    (typeof paramsFilter.q !== "undefined" && paramsFilter.q === "")
  ) {
    paramsFilter = {};
  }
  const flatFilter = fetchUtils.flattenObject(paramsFilter);
  const filter = Object.keys(flatFilter).map(key => {
    const query = flatFilter[key];
    let [field, keyOp] = key.split("||");
    const [valueOp, value] = query.split ? query.split("||") : [query];
    const keyOperator = keyOp ? keyOp : CondOperator.EQUALS;
    const op = value ? valueOp : keyOperator;

    if (field.indexOf("_") === 0 && field.indexOf(".") > -1) {
      field = field.split(/\.(.+)/)[1];
    }

    return { field, operator: op, value: value ? value : valueOp };
  });
  return filter;
};

const parseFilters = (filter, additionalFilters = []) => {
  const filterKeys = Object.keys(filter || {});
  const [search] = filterKeys
    .filter((parameter) => isSearchQuery(filter[parameter]))
    .map(parameter => filter[parameter]);
  const { searchQuery } = search || {};
  const filters = filterKeys
    .reduce((all, parameter) => ({
      ...all,
      ...(
        !isSearchQuery(filter[parameter])
          ? { [parameter]: filter[parameter] }
          : {}
      ),
    }), {});
  const parsedFilter = [...composeFilter(filters), ...additionalFilters];
  const formattedFilters = Object.keys(parsedFilter)
    .reduce((formatted, parameter) => {
      const { field, operator, value } = parsedFilter[parameter];
      return ({ ...formatted, [field]: { [operator]: value } });
    }, {});

  return {
    filter: parsedFilter,
    search: searchQuery
      ? {
        ...searchQuery,
        ...formattedFilters,
      }
      : searchQuery
  }
};

const formatErrorNotifications = error => {
  const message = error.message;
  if (message && message.join) {
    error.message = message.join(', \n');
  }
  throw error;
};

export default (
  apiUrl,
  getHeaders = () => ({}),
  httpClient = fetchUtils.fetchJson
) => {
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};
    const headers = getHeaders();
    if (headers) {
      options.headers = headers;
    }
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const filters = parseFilters(params.filter, [
          ...(softDeletedResources.includes(resource) ? [deletedAtFilter] : []),
        ]);
        const query = RequestQueryBuilder.create(filters)
          .setLimit(perPage)
          .setPage(page)
          .sortBy(params.sort)
          .setOffset((page - 1) * perPage)
          .query();

        url = formatUrl(apiUrl, resource, query);

        break;
      }
      case GET_ONE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
      }
      case GET_MANY: {
        const query = RequestQueryBuilder.create()
          .setFilter({
            field: 'id',
            operator: CondOperator.IN,
            value: `${params.ids}`,
          })
          .query();
        url = formatUrl(apiUrl, resource, query);
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const filters = parseFilters(params.filter, [
          ...(softDeletedResources.includes(resource) ? [deletedAtFilter] : []),
          {
            field: params.target,
            operator: CondOperator.EQUALS,
            value: params.id
          }
        ]);

        const query = RequestQueryBuilder.create(filters)
          .sortBy(params.sort)
          .setLimit(perPage)
          .setOffset((page - 1) * perPage)
          .query();

        url = formatUrl(apiUrl, resource, query);

        break;
      }
      case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PATCH';
        options.body = JSON.stringify(params.data);
        break;
      }
      case CREATE: {
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      }
      case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  const convertHTTPResponse = (response, type, resource, params) => {
    const { json } = response;

    switch (type) {
      case GET_LIST: {
        return {
          data: json.data,
          total: json.total,
        };
      }
      case GET_MANY: {
        return {
          data: json.data,
          total: json.total,
        };
      }
      case GET_MANY_REFERENCE: {
        return {
          data: json.data,
          total: json.total,
        };
      }
      case CREATE:
        return { data: { ...params.data, id: json.id } };
      case UPDATE:
        return { data: { ...params.data, id: json.id } };
      case DELETE:
        return { data: params.previousData };
      default:
        return { data: json };
    }
  };

  return (type, resource, params) => {
    const headers = getHeaders();

    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
            headers,
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json),
      }));
    }
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
            headers,
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json),
      }));
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options)
      .then(response => convertHTTPResponse(response, type, resource, params))
      .catch(formatErrorNotifications);
  };
};
