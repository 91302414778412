import React from 'react';
import { useSelector, connect } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import SettingsIcon from '@material-ui/icons/Settings';

import { loadAuthState } from '../../store/localStorage';

const Menu = ({ onMenuClick, practiceId }) => {
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const authState = loadAuthState();
  const appMetadata = authState && authState.idTokenPayload[process.env.REACT_APP_APP_META_KEY];
  const isInternal =
    appMetadata && appMetadata.episourceAccess && appMetadata.episourceAccess.isInternal;

  return (
    <div>
      {resources
        .filter(r => isInternal || r.name !== 'practices')
        .map(resource => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={(resource.options && resource.options.label) || resource.name}
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
      {practiceId !== 0 && (
        <MenuItemLink
          to={`/practices/${practiceId}`}
          primaryText="Practice Settings"
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  practiceId: state.practiceId,
});

export default connect(mapStateToProps, undefined)(Menu);
