import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import auth from '../lib/auth';
import history from '../historyWrapper';

const Callback = props => {
  const { location } = props;
  const [error, setError] = useState('');

  useEffect(() => {
    if (/access_token|id_token|error/.test(location.hash)) {
      auth
        .handleAuthentication()
        .then(() => {
          setError('');
          history.push({ pathname: '/appointments' });
        })
        .catch(err => {
          history.push({ pathname: `/authentication-error`, search: `error=${err.error}&description=${err.errorDescription}` });
        });
    }
  }, [location.hash]);

  return (
    <Card>
      <CircularProgress />
    </Card>
  );
};

export default Callback;
