import auth from './lib/auth';

export default {
  login: () => {
    return new Promise((resolve, reject) => {
      if (auth.isAuthenticated()) {
        return resolve();
      }
      return reject();
    });
  },
  logout: () => {
    auth.signOut();
    return Promise.resolve();
  },
  checkAuth: () => {
    return new Promise((resolve, reject) => {
      if (auth.checkAuthentication()) {
        return resolve({ redirectTo: '/appointments' });
      }
      return reject();
    });
  },
  getPermissions: () => {
    return new Promise(resolve => {
      return resolve();
    });
  },
  checkError: error => {
    if ('status' in error) {
      const { status } = error;
      if (status === 401 || status === 403) {
        auth.signOut();
        return Promise.reject();
      }
    }
    return Promise.resolve();
  },
};
