/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import { useLogin } from 'react-admin';

export const LoginPage = props => {
  const { auth, location } = props;
  const login = useLogin();

  useEffect(() => {
    if (!auth.isAuthenticated()) {
      auth.signIn();
    }
  }, [auth, location.hash, login]);

  return null;
};
