/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Create, List, Datagrid, TextField, TextInput, SimpleForm } from 'react-admin';
import { TimeZoneDropDown } from './TimeZoneDropDown';
import Edit from './PessimisticEdit';
import {
  isAddress,
  isEmail,
  isName,
  isPhoneNumber,
  isTin,
  lengthIsLessThan,
  required,
} from '../util/validators';
import { requiredHelperText } from '../util/util';

const MAX_SHORT_NAME_LENGTH = 15;

export const PracticeCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput validate={required()} source="extClientId" label="External Client ID" />
      <TextInput helperText={requiredHelperText} validate={isName()} source="name" />
      <TextInput
        helperText={requiredHelperText}
        validate={lengthIsLessThan(MAX_SHORT_NAME_LENGTH, isName())}
        label="Short Name"
        source="shortName"
      />
      <TextInput helperText={requiredHelperText} validate={isTin()} source="TIN" label="TIN" />
      <TimeZoneDropDown helperText={requiredHelperText} validate={required()} source="timezone" />
      <TextInput
        helperText={requiredHelperText}
        validate={isAddress()}
        label="Practice Address"
        source="serviceAddress"
      />
      <TextInput
        helperText={requiredHelperText}
        validate={isPhoneNumber()}
        label="Scheduler Phone"
        source="contactPhone"
      />
      <TextInput
        helperText={requiredHelperText}
        validate={isEmail()}
        label="Scheduler Email"
        source="contactEmail"
      />
      <TextInput
        helperText={requiredHelperText}
        validate={isPhoneNumber()}
        label="Practice Phone"
        source="schedulingPhone"
      />
      <TextInput
        helperText={requiredHelperText}
        validate={isPhoneNumber()}
        source="techSupportPhone"
      />
    </SimpleForm>
  </Create>
);

export const PracticeList = props => (
  <List {...props} title="Practices">
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="TIN" label="TIN" />
      <TextField source="serviceAddress" />
      <TextField source="contactEmail" />
      <TextField source="contactPhone" />
    </Datagrid>
  </List>
);

export const PracticeEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput disabled validate={required()} source="extClientId" label="External Client ID" />
      <TextInput helperText={requiredHelperText} validate={isName()} source="name" />
      <TextInput
        helperText={requiredHelperText}
        validate={lengthIsLessThan(MAX_SHORT_NAME_LENGTH, isName())}
        label="Short Name"
        source="shortName"
      />
      <TextInput helperText={requiredHelperText} validate={isTin()} source="TIN" label="TIN" />
      <TimeZoneDropDown helperText={requiredHelperText} validate={required()} source="timezone" />
      <TextInput
        helperText={requiredHelperText}
        validate={isAddress()}
        label="Practice Address"
        source="serviceAddress"
      />
      <TextInput
        helperText={requiredHelperText}
        validate={isPhoneNumber()}
        label="Scheduler Phone"
        source="contactPhone"
      />
      <TextInput
        helperText={requiredHelperText}
        validate={isEmail()}
        label="Scheduler Email"
        source="contactEmail"
      />
      <TextInput
        helperText={requiredHelperText}
        validate={isPhoneNumber()}
        label="Practice Phone"
        source="schedulingPhone"
      />
      <TextInput
        helperText={requiredHelperText}
        validate={isPhoneNumber()}
        source="techSupportPhone"
      />
    </SimpleForm>
  </Edit>
);
