import React from 'react';
import { Admin, Resource, ShowGuesser } from 'react-admin';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import BusinessIcon from '@material-ui/icons/Business';
import Layout from './components/layout/Layout';

import {
  AppointmentCreate,
  AppointmentList,
  AppointmentEdit,
  AppointmentShow,
} from './components/appointments';
import { MemberCreate, MemberEdit, MemberList } from './components/members';
import { ProviderCreate, ProviderEdit, ProviderList } from './components/providers';
import { LoginPage } from './components/login';
import { PracticeCreate, PracticeEdit, PracticeList } from './components/practices';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import customRoutes from './customRoutes';
import history from './historyWrapper';
import auth from './lib/auth';
import practiceReducer from './redux/reducers/practiceReducer';

const login = props => <LoginPage {...props} auth={auth} />;
const App = () => (
  <Admin
    layout={Layout}
    loginPage={login}
    customReducers={{ practiceId: practiceReducer }}
    dataProvider={dataProvider}
    authProvider={authProvider}
    history={history}
    customRoutes={customRoutes}
  >
    <Resource
      name="appointments"
      options={{ label: 'Appointments' }}
      icon={AccessAlarmIcon}
      create={AppointmentCreate}
      list={AppointmentList}
      edit={AppointmentEdit}
      show={AppointmentShow}
    />
    <Resource
      name="members"
      options={{ label: 'Patients' }}
      icon={PeopleOutlineIcon}
      create={MemberCreate}
      list={MemberList}
      edit={MemberEdit}
      show={ShowGuesser}
    />
    <Resource
      name="providers"
      options={{ label: 'Providers' }}
      icon={AssignmentIndIcon}
      create={ProviderCreate}
      list={ProviderList}
      edit={ProviderEdit}
      show={ShowGuesser}
    />
    <Resource
      name="practices"
      options={{ label: 'Practices' }}
      icon={BusinessIcon}
      create={PracticeCreate}
      list={PracticeList}
      edit={PracticeEdit}
      show={ShowGuesser}
    />
  </Admin>
);

export default App;
