/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Create, List, Datagrid, TextField, TextInput, EmailField, SimpleForm } from 'react-admin';
import { connect } from 'react-redux';

import Edit from './PessimisticEdit';
import { TimeZoneDropDown } from './TimeZoneDropDown';
import { isEmail, isName, isNpi, isOptional, isPhoneNumber, required } from '../util/validators';
import { optionalHelperText, requiredHelperText } from '../util/util';
import WithPracticeToolbar from './overrides/WithPracticeToolbar';

export const BaseList = ({ practiceId, dispatch, ...props }) => (
  <List
    filter={{
      ...(practiceId ? { 'practices.id': `eq||${practiceId}` } : {}),
    }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField label="Mobile" source="phone" />
      <EmailField source="email" />
      <TextField source="NPI" label="NPI" />
      <TextField source="timezone" />
    </Datagrid>
  </List>
);

const mapStateToProps = state => ({
  practiceId: state.practiceId,
});

export const ProviderList = connect(mapStateToProps, undefined)(BaseList);

export const ProviderCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<WithPracticeToolbar many />}>
      <TextInput helperText={requiredHelperText} validate={isName()} source="name" />
      <TextInput
        helperText={optionalHelperText}
        validate={isOptional(isPhoneNumber())}
        label="Mobile"
        source="phone"
      />
      <TextInput helperText={requiredHelperText} validate={isEmail()} source="email" />
      <TextInput helperText={requiredHelperText} validate={isNpi()} source="NPI" label="NPI" />
      <TimeZoneDropDown validate={required()} source="timezone" />
    </SimpleForm>
  </Create>
);

export const ProviderEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput helperText={requiredHelperText} validate={isName()} source="name" />
      <TextInput
        helperText={optionalHelperText}
        validate={isOptional(isPhoneNumber())}
        label="Mobile"
        source="phone"
      />
      <TextInput helperText={requiredHelperText} validate={isEmail()} source="email" />
      <TextInput helperText={requiredHelperText} validate={isNpi()} source="NPI" label="NPI" />
      <TimeZoneDropDown validate={required()} source="timezone" />
    </SimpleForm>
  </Edit>
);
