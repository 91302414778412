import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import {
  AppBar,
  Button,
  Container,
  FormControl as MuiFormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Step,
  Stepper,
  StepLabel,
  Switch,
  TextField as MuiTextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { get, isArray, isEmpty, isObject, snakeCase } from 'lodash';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { getAllTimezones, states } from '../../util/util';
import schemaObj from '../../util/signupSchema';
import dataProvider from '../../dataProvider';
import mixpanel from '../../util/mixpanel';

const countries = ['US'];

const timezones = getAllTimezones(countries).map((name, id) => ({ id, name }));
export const DEFAULT_TIME_ZONE = 'America/Los_Angeles';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
  },
  logo: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(2),
  },
  formGroup: {
    'maxWidth': '600px',
    'marginBottom': theme.spacing(4),
    '& > *': {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    '& > * > *': {
      width: '100%',
    },
  },
  error: {
    color: 'red',
  },
  buttons: {},
  hidden: {
    display: 'none',
  },
  terms: {
    fontSize: 'smaller',
  },
}));

const FormControl = withStyles({})(props => <MuiFormControl variant="filled" {...props} />);
const TextField = withStyles({})(props => <MuiTextField variant="filled" {...props} />);

const getSteps = () => [
  'Admin/Scheduler Information',
  'Practice Information',
  'Office Address',
  'Terms and Conditions',
];

const getStepMembers = step =>
  [
    [
      'firstName',
      'lastName',
      'email',
      'password',
      'phone',
      'timezone',
      'isHealthcareProvider',
      'npi',
    ],
    [
      'schedulingPhone',
      'practiceTimezone',
      'techSupportPhone',
      'clientName',
      'practiceShortName',
      'tin',
      'practiceNpi',
    ],
    ['address', 'city', 'state', 'zipcode', 'contactEmail'],
    ['acceptedLegalTerms'],
  ][step];

// Create an object keyed by field name with the value of the step number that
// the field appears on. This makes jumping to submission errors more efficient.
const membersByStep = getSteps()
  .map((v, k) => getStepMembers(k))
  .reduce(
    (membersMap, members, k) => ({
      ...membersMap,
      ...members.reduce((newMembersMap, member) => ({
        ...newMembersMap,
        [member]: k,
      })),
    }),
    {}
  );

export default () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [validatedStep, setValidatedStep] = React.useState(0);
  const [submitSucceeded, setSubmitSucceeded] = React.useState(false);
  const [startTime] = React.useState(Date.now());
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = React.useState('');
  const [didStep, setDidStep] = React.useState(false);
  const steps = getSteps();
  const getHiddenClass = step => {
    if (step !== activeStep) {
      return classes.hidden;
    }
    return '';
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      mixpanel.track(
        'signup abandoned',
        {
          abandonedAt: `Step ${activeStep + 1}: ${getSteps()[activeStep]}`,
          duration: Date.now() - startTime,
        },
        { transport: 'sendBeacon' }
      );
    };
  });

  const onBack = () => {
    setActiveStep(prevStep => prevStep - 1);
  };
  const onSubmit = async values => {
    if (executeRecaptcha) {
      setToken(
        await executeRecaptcha(`signup__${snakeCase(getSteps()[activeStep]).toLowerCase()}`)
      );
    }
    if (activeStep === steps.length - 1) {
      try {
        const { data } = await dataProvider('CREATE', 'accounts/signup', {
          data: {
            ...values,
            recaptchaResponse: token,
          },
        });
        mixpanel.identify(data.id);
        mixpanel.track('signup success', {
          signupBegin: new Date(startTime).toISOString(),
          signupEnd: new Date().toISOString(),
          duration: Date.now() - startTime,
        });
        window.onbeforeunload = undefined;
        setSubmitSucceeded(true);
        return {};
      } catch (err) {
        const errors = get(err, 'body.errors', get(err, 'body.message'));
        if (errors && (isArray(errors) || isObject(errors))) {
          const stepsWithErrors = [];
          let ret = {
            [FORM_ERROR]: 'Unable to create account. Please try again later',
          };
          if (isArray(errors)) {
            ret = errors.reduce((errs, error) => {
              const [key, ...rest] = error.split(' ');
              if (typeof membersByStep[key] === 'undefined') {
                return {
                  ...errs,
                  [FORM_ERROR]: `Server error: ${error}`,
                };
              }

              stepsWithErrors.push(membersByStep[key]);
              return {
                ...errs,
                [key]: rest.join(' '),
              };
            }, {});
          } else {
            ret = Object.keys(errors).reduce((errs, key) => {
              if (typeof membersByStep[key] === 'undefined') {
                return {
                  ...errs,
                  [FORM_ERROR]: `Server error: ${errors[key]}`,
                };
              }

              stepsWithErrors.push(membersByStep[key]);
              return {
                ...errs,
                [key]: errors[key],
              };
            }, {});
          }

          // Jump to the first step that contains an error.
          const newActiveStep = stepsWithErrors.sort((l, r) => l - r)[0];
          if (typeof newActiveStep !== 'undefined') {
            setActiveStep(newActiveStep);
          }

          mixpanel.track('signup server side validation error', ret);
          return ret;
        }
        if (errors) {
          mixpanel.track('signup server side validation error', { errors });
          return { [FORM_ERROR]: errors };
        }
        mixpanel.track('signup server side error', { errors: err });
        return {
          [FORM_ERROR]: 'Unable to create account. Please try again later',
        };
      }
    } else {
      return setActiveStep(prevStep => prevStep + 1);
    }
  };
  const onValidate = values => {
    const errors = getStepMembers(activeStep).reduce((errs, field) => {
      // This could be handled more elegantly by using a conditional within a
      // schema definition; however, joi doesn't support partial schema
      // validation. We could define a schema for each step but that starts to
      // feel overly complicated, so here we are.
      if (activeStep === validatedStep && (field !== 'npi' || values.isHealthcareProvider)) {
        const validated = schemaObj[field].validate(values[field]);
        if (validated.error) {
          return {
            ...errs,
            [field]: validated.error.details[0].message,
          };
        }
      }
      return errs;
    }, {});

    if (didStep && !isEmpty(errors)) {
      mixpanel.track('signup client side validation error', errors);
    }

    return errors;
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <img
            alt="Episource Logo"
            className={classes.logo}
            src={`${process.env.PUBLIC_URL}/logo@2x-1-300x71.png`}
          />
          <Typography variant="h5">TeleHealth Sign Up</Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" className={classes.container}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Form
          onSubmit={onSubmit}
          validate={onValidate}
          render={({ submitError, handleSubmit, submitting, values, errors, touched }) => {
            return (
              <div>
                <form onSubmit={handleSubmit}>
                  {submitError && <div className={classes.error}>{submitError}</div>}
                  <div className={getHiddenClass(0)}>
                    <Typography variant="h6">Admin/Scheduler Information</Typography>
                    <div className={classes.formGroup}>
                      <Field
                        name="firstName"
                        render={props => (
                          <div className={classes.nameField}>
                            <TextField
                              name={props.input.name}
                              label="First Name"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                              variant="filled"
                            />
                          </div>
                        )}
                      />
                      <Field
                        name="lastName"
                        render={props => (
                          <div className={classes.nameField}>
                            <TextField
                              name={props.input.name}
                              label="Last Name"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                            />
                          </div>
                        )}
                      />
                      <Field
                        name="email"
                        render={props => (
                          <div>
                            <TextField
                              name={props.input.name}
                              label="Admin Email Address"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                            />
                          </div>
                        )}
                      />
                      <Field
                        name="password"
                        render={props => (
                          <div>
                            <TextField
                              name={props.input.name}
                              label="Admin Password"
                              value={props.input.value}
                              type="password"
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                            />
                          </div>
                        )}
                      />
                      <Field
                        name="phone"
                        render={props => (
                          <div>
                            <TextField
                              name={props.input.name}
                              label="Scheduler Phone Number"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                            />
                          </div>
                        )}
                      />
                      <FormControl
                        className={classes.timezones}
                        error={!!(touched.timezone && errors.timezone)}
                      >
                        <InputLabel htmlFor="timezone">Timezone</InputLabel>
                        <Field
                          name="timezone"
                          render={props => (
                            <Select
                              name={props.input.name}
                              value={props.input.value}
                              onChange={props.input.onChange}
                            >
                              {timezones.map(({ name }) => (
                                <MenuItem key={name} value={name}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {touched.timezone && errors.timezone && (
                          <FormHelperText>{errors.timezone}</FormHelperText>
                        )}
                      </FormControl>
                      <Field
                        name="isHealthcareProvider"
                        type="checkbox"
                        render={props => (
                          <div>
                            <FormControlLabel
                              label="Are you a healthcare provider?"
                              control={
                                <Switch
                                  name={props.input.name}
                                  value={props.input.value}
                                  checked={props.input.checked}
                                  onChange={props.input.onChange}
                                  color="primary"
                                />
                              }
                            />
                          </div>
                        )}
                      />
                      <Field
                        name="npi"
                        render={props => (
                          <div>
                            <TextField
                              disabled={!values.isHealthcareProvider}
                              name={props.input.name}
                              label="Provider NPI"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className={getHiddenClass(1)}>
                    <Typography variant="h6">Practice Information</Typography>
                    <div className={classes.formGroup}>
                      <Field
                        name="schedulingPhone"
                        render={props => (
                          <div>
                            <TextField
                              name={props.input.name}
                              label="Practice Phone Number"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                            />
                          </div>
                        )}
                      />
                      <FormControl
                        className={classes.timezones}
                        error={!!(touched.practiceTimezone && errors.practiceTimezone)}
                      >
                        <InputLabel htmlFor="practiceTimezone">Practice Time Zone</InputLabel>
                        <Field
                          name="practiceTimezone"
                          render={props => (
                            <Select
                              name={props.input.name}
                              value={props.input.value}
                              onChange={props.input.onChange}
                            >
                              {timezones.map(({ name }) => (
                                <MenuItem key={name} value={name}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {touched.practiceTimezone && errors.practiceTimezone && (
                          <FormHelperText>{errors.practiceTimezone}</FormHelperText>
                        )}
                      </FormControl>
                      <Field
                        name="techSupportPhone"
                        render={props => (
                          <div>
                            <TextField
                              name={props.input.name}
                              label="Tech Support Phone Number"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                            />
                          </div>
                        )}
                      />
                      <Field
                        name="clientName"
                        render={props => (
                          <div className={classes.nameField}>
                            <TextField
                              name={props.input.name}
                              label="Practice Name"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                            />
                          </div>
                        )}
                      />
                      <Field
                        name="practiceShortName"
                        render={props => (
                          <div>
                            <TextField
                              name={props.input.name}
                              label="Practice Short Name"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                (props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)) ||
                                '15-character limit'
                              }
                            />
                          </div>
                        )}
                      />
                      <Field
                        name="tin"
                        render={props => (
                          <div>
                            <TextField
                              name={props.input.name}
                              label="Practice Tin"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                            />
                          </div>
                        )}
                      />
                      <Field
                        name="practiceNpi"
                        render={props => (
                          <div>
                            <TextField
                              name={props.input.name}
                              label="Practice NPI"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className={getHiddenClass(2)}>
                    <Typography variant="h6">Office Address</Typography>
                    <div className={classes.formGroup}>
                      <Field
                        name="address"
                        render={props => (
                          <div className={classes.addressField}>
                            <TextField
                              name={props.input.name}
                              label="Address"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                            />
                          </div>
                        )}
                      />
                      <Field
                        name="city"
                        render={props => (
                          <div>
                            <TextField
                              name={props.input.name}
                              label="City"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                            />
                          </div>
                        )}
                      />
                      <FormControl
                        error={!!(touched.state && errors.state)}
                        className={classes.state}
                      >
                        <InputLabel htmlFor="state">State</InputLabel>
                        <Field
                          name="state"
                          render={props => (
                            <Select
                              name={props.input.name}
                              value={props.input.value}
                              onChange={props.input.onChange}
                            >
                              {Object.keys(states).map(abv => (
                                <MenuItem key={abv} value={abv}>
                                  {states[abv]}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                      <Field
                        name="zipcode"
                        render={props => (
                          <div>
                            <TextField
                              name={props.input.name}
                              label="Postal Code"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                            />
                          </div>
                        )}
                      />
                      <Field
                        name="contactEmail"
                        render={props => (
                          <div>
                            <TextField
                              name={props.input.name}
                              label="Contact Email"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                !!(
                                  props.meta.touched &&
                                  (props.meta.error || props.meta.submitError)
                                )
                              }
                              helperText={
                                props.meta.touched && (props.meta.error || props.meta.submitError)
                              }
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className={getHiddenClass(3)}>
                    <Typography variant="h6">Terms and Conditions</Typography>
                    <div className={classes.formGroup}>
                      <div>
                        By submitting this form you are agreeing to the EpiSource TeleHealth terms
                        and conditions:
                        <ul className={classes.terms}>
                          <li>
                            <a
                              href={`${process.env.PUBLIC_URL}/EULA.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              End User License Agreement
                            </a>
                          </li>
                          <li>
                            <a
                              href={`${process.env.PUBLIC_URL}/PrivacyPolicy.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy Policy
                            </a>
                          </li>
                          <li>
                            <a
                              href={`${process.env.PUBLIC_URL}/BAA.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Business Associate Agreement
                            </a>
                          </li>
                        </ul>
                      </div>
                      <Field
                        name="acceptedLegalTerms"
                        type="checkbox"
                        render={props => (
                          <div>
                            <FormControlLabel
                              label="I have read and agree to the terms and conditions"
                              control={
                                <Switch
                                  name={props.input.name}
                                  value={props.input.value}
                                  checked={props.input.checked}
                                  onChange={props.input.onChange}
                                  color="primary"
                                />
                              }
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className={classes.buttons}>
                    <Button onClick={onBack} disabled={activeStep === 0}>
                      Back
                    </Button>
                    <Button
                      className={getHiddenClass(activeStep === steps.length - 1 ? activeStep : -1)}
                      variant="contained"
                      type="submit"
                      disabled={submitting || !values.acceptedLegalTerms}
                    >
                      Sign Up
                    </Button>
                    <Button
                      className={getHiddenClass(activeStep !== steps.length - 1 ? activeStep : -1)}
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        setDidStep(true);
                        setValidatedStep(activeStep);
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </form>
                {submitSucceeded && (
                  <Redirect
                    to={{
                      pathname: '/signupSuccess',
                      state: {
                        accountEmail: values.email,
                      },
                    }}
                  />
                )}
              </div>
            );
          }}
        />
      </Container>
    </div>
  );
};
