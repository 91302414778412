/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import auth0 from 'auth0-js';
import axios from 'axios';
import { loadAuthState, saveAuthState, destroyState } from '../store/localStorage';

const {
  REACT_APP_CLIENTID,
  REACT_APP_DOMAIN,
  REACT_APP_AUDIENCE,
  REACT_APP_REDIRECTURI,
  REACT_APP_LOGIN_URI,
} = process.env;

const config = {
  responseType: 'id_token token',
  scope: 'openid profile',
  clientID: REACT_APP_CLIENTID,
  domain: REACT_APP_DOMAIN,
  audience: REACT_APP_AUDIENCE,
  redirectUri: REACT_APP_REDIRECTURI,
};

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth(config);
    this.getProfile = this.getProfile.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.getExpiresAt = this.getExpiresAt.bind(this);
    this.refreshUserToken = this.refreshUserToken.bind(this);
    this.checkAuthentication = this.checkAuthentication.bind(this);
    this.checkLocalStore = this.checkLocalStore.bind(this);
    this.getDataUserInfo = this.getDataUserInfo.bind(this);
  }

  getProfile() {
    return this.profile;
  }

  // eslint-disable-next-line consistent-return
  getIdToken() {
    this.checkLocalStore();
    if (this.idToken) {
      return this.idToken;
    }
  }

  getAccessToken() {
    this.checkLocalStore();
    if (this.accessTkn) {
      return this.accessTkn;
    }
    return null;
  }

  getExpiresAt() {
    this.checkLocalStore();
    if (this.expiresAt) {
      return this.expiresAt;
    }
    return null;
  }

  populateFromParams(loginParams) {
    this.idToken = loginParams.idToken;
    this.profile = loginParams.idTokenPayload;
    this.accessTkn = loginParams.accessToken;
    this.expiresAt = loginParams.idTokenPayload.exp * 1000;
  }

  checkLocalStore() {
    const state = loadAuthState();
    if (state && state.idToken && state.idTokenPayload && state.accessToken) {
      this.populateFromParams(state);
    }
  }

  checkAuthentication() {
    this.checkLocalStore();
    return this.isAuthenticated();
  }

  isAuthenticated() {
    return new Date().getTime() < this.expiresAt;
  }

  signIn() {
    this.auth0.authorize({ scope: 'openid profile email' });
  }

  async getDataUserInfo() {
    const requestURL = `https://${config.domain}/userinfo?access_token=${encodeURIComponent(
      this.getAccessToken()
    )}`;
    const response = await axios.get(requestURL);
    return response.data;
  }

  refreshUserToken() {
    const self = this;
    return new Promise((resolve, reject) => {
      self.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        saveAuthState(authResult);
        return resolve();
      });
    });
  }

  handleAuthentication() {
    const self = this;
    return new Promise((resolve, reject) => {
      self.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        saveAuthState(authResult);
        return resolve();
      });
    });
  }

  signOut() {
    // clear id token, profile, and expiration
    this.idToken = null;
    this.profile = null;
    this.expiresAt = null;
    destroyState();
    // https://auth0.com/docs/api/authentication#logout
    this.auth0.logout({
      returnTo: REACT_APP_LOGIN_URI,
      client_id: config.clientID,
    });
  }
}

const auth0Client = new Auth();

export default auth0Client;
