import React, { useEffect } from 'react';
import {
  ReferenceInput,
  SelectInput,
  SimpleForm,
  useQueryWithStore,
  useRefresh,
} from 'react-admin';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { setPractice } from '../../redux/actions/practiceActions';

const useStyles = makeStyles({
  root: {
    // border: "1px solid #ced4da",
    '& div.MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& div.MuiFormControl-marginDense': {
      marginTop: 10,
      marginBottom: 10,
    },
    '& p.MuiFormHelperText-root': {
      display: 'none',
    },
    // default value
    '& .MuiFormLabel-root': {
      color: '#fff',
    },
    // border
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff',
    },
    // selected value
    '& .MuiInputBase-root': {
      color: '#fff',
    },
    // dropdown icon
    '& .MuiSelect-icon': {
      color: '#fff',
    },
  },
});

export const payload = {
  pagination: { page: 1, perPage: 10 },
  sort: { field: 'id', order: 'ASC' },
  filter: '',
};

const PracticeSwitcher = props => {
  const classes = useStyles();
  const refresh = useRefresh();
  const { loaded, error, data } = useQueryWithStore({
    type: 'getList',
    resource: 'practices',
    payload,
  });
  useEffect(() => {
    if (data && data.length >= 1) {
      const practiceId = data[0].id;
      props.setPractice(practiceId);
      refresh();
    }
  }, [loaded]);

  if (!loaded) {
    return 'Loading...';
  }
  if (error) {
    return `${error}`;
  }
  if (data && data.length <= 1) return <div> </div>;

  return (
    <SimpleForm className={classes.root} toolbar={null}>
      <ReferenceInput
        defaultValue={data && data[0].id}
        label="Practice"
        source="practiceId"
        reference="practices"
        variant="outlined"
        perPage={99}
        onChange={e => {
          props.setPractice(e.target.value);
          refresh();
        }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  );
};

export default connect(null, {
  setPractice,
})(PracticeSwitcher);
