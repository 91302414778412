const AUTH_STATE_KEY = 'userstate';

// base functions
const loadState = storageKey => {
  try {
    const serializedState = localStorage.getItem(storageKey);
    if (serializedState === null) {
      return undefined;
    }
    const store = JSON.parse(serializedState);
    return store;
  } catch (err) {
    return undefined;
  }
};
const saveState = (state, storageKey) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(storageKey, serializedState);
  } catch (err) {
    // noop
  }
};

// exports
export const loadAuthState = () => loadState(AUTH_STATE_KEY);
export const saveAuthState = state => saveState(state, AUTH_STATE_KEY);
export const destroyState = () => localStorage.removeItem(AUTH_STATE_KEY);
