import Creatable from 'react-select/creatable/dist/react-select.esm';
import React, { useState } from 'react';
import {
  Button,
  Create,
  DateInput,
  FormTab,
  ReferenceInput,
  sanitizeEmptyValues,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { DateTimeInput } from 'react-admin-date-inputs';
import { useField, Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import { connect } from 'react-redux';

import IconCancel from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import PatientModal from '../patientModal';
import ProviderModal from '../providerModal';
import {
  fullNameRenderer,
  optionalHelperText,
  parseName,
  requiredHelperText,
} from '../../util/util';
import { TimeZoneDropDown, DEFAULT_TIME_ZONE } from '../TimeZoneDropDown';
import { isEmail, isName, isNpi, isOptional, isPhoneNumber, required } from '../../util/validators';

const errorColor = 'red';
const textField = {
  margin: '0',
  fontSize: '0.75rem',
  position: 'relative',
  left: '13px',
  top: '-12px',
  marginTop: '3px',
  textAlign: 'left',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: '400',
  lineHeight: '0.001',
  letterSpacing: '0.03333em',
};
const useStyles = makeStyles({
  allowOverflow: {
    '& .MuiCard-root': { overflow: 'visible !important' },
  },
  removeButton: {
    left: '82px',
  },
  errorMessage: {
    ...textField,
    color: errorColor,
  },
  errorField: {
    '& div': {
      borderColor: errorColor,
    },
  },
  helperText: {
    ...textField,
    color: 'grey',
  },
});

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

const PROVIDER_NAME = 'providerName';
const PATIENT_FIRST_NAME = 'patientFirstName';
const PATIENT_LAST_NAME = 'patientLastName';
const PATIENT_PHONE = 'patientPhone';
const PATIENT_EMAIL = 'patientEmail';
const PATIENT_BIRTH_DATE = 'patientBirthDate';
const PROVIDER_EMAIL = 'providerEmail';
const PROVIDER_PHONE = 'providerPhone';
const PROVIDER_ID = 'providerId';
const PATIENT_ID = 'patientId';
const PROVIDER_TIME_ZONE = 'providerTimeZone';
const PROVIDER_NPI = 'providerNPI';
const APPOINTMENT_TIMEZONE = 'appointmentTimezone';
const BROWSER_TIMEZONE = moment.tz.guess();

const formatFormOutput = data => {
  const {
    [PATIENT_BIRTH_DATE]: patientBirthDate,
    [PATIENT_PHONE]: patientPhone,
    [PATIENT_EMAIL]: patientEmail,
    [PATIENT_FIRST_NAME]: patientFirstName,
    [PATIENT_LAST_NAME]: patientLastName,
    [PROVIDER_NAME]: providerName,
    [PROVIDER_PHONE]: providerPhone,
    [PROVIDER_EMAIL]: providerEmail,
    [PROVIDER_ID]: providerId,
    [PATIENT_ID]: memberId,
    [PROVIDER_NPI]: npi,
    [PROVIDER_TIME_ZONE]: timezone,
    time,
    practiceId,
  } = data;
  let patient;
  let provider;
  if (patientFirstName) {
    patient = {
      phone: patientPhone,
      email: patientEmail,
      birthDate: patientBirthDate,
      firstName: patientFirstName,
      lastName: patientLastName,
      practiceId,
    };
  }
  if (providerName) {
    provider = {
      phone: providerPhone,
      email: providerEmail,
      name: providerName,
      timezone,
      NPI: npi,
      practiceIds: [practiceId],
    };
  }
  return {
    member: patient,
    provider,
    memberId,
    providerId,
    practiceId,
    time,
  };
};

const selectStyles = {
  container: styles => ({
    ...styles,
    paddingTop: '8px',
    marginBottom: '25px',
  }),
  menu: styles => ({ ...styles, zIndex: 5 }),
};

function NameInputField({
  meta,
  userName,
  name: fieldName,
  choices,
  selected,
  setSelected,
  ...props
}) {
  const invalid = meta.submitFailed && !userName && !selected;
  const options = choices.map(({ id, name, ...choice }) => ({
    label: name || fullNameRenderer(choice),
    value: id,
  }));
  const classes = useStyles();
  const {
    input: { onChange },
  } = useField(fieldName);
  return (
    <Field
      {...props}
      name={fieldName}
      value={userName}
      options={options}
      validate={() => (invalid && props.validate ? props.validate() : undefined)}
      component={args => {
        return (
          <>
            <Creatable
              {...args}
              isDisabled={!!userName}
              styles={selectStyles}
              options={options}
              className={`${args.className}${invalid ? ` ${classes.errorField}` : ''}`}
              defaultInputValue={selected}
              inputValue={userName || undefined}
              onChange={data => {
                onChange(data.value);
                setSelected(data.label);
              }}
              placeholder={args.placeholder}
              onCreateOption={args.onCreate}
            />
            <p className={invalid ? classes.errorMessage : classes.helperText}>{args.helperText}</p>
          </>
        );
      }}
    />
  );
}

const ProviderTab = ({
  setName,
  setPhoneNumber,
  setEmail,
  setTimezone,
  setNPI,
  name,
  phone,
  email,
  timezone,
  npi,
  buttonClassName,
  toAppointmentTab,
  ...props
}) => {
  return (
    <FormTab {...props} label="provider">
      <Button
        className={buttonClassName}
        label="remove"
        onClick={() => {
          toAppointmentTab();
          setName('');
        }}
      >
        <IconCancel />
      </Button>
      <TimeZoneDropDown
        source="providerTimeZone"
        name={PROVIDER_TIME_ZONE}
        value={timezone}
        onChange={({ target }) => setTimezone(target.value)}
      />
      <TextInput
        source="providerName"
        label="Name"
        name={PROVIDER_NAME}
        helperText={requiredHelperText}
        validate={isName()}
        value={name}
        onChange={({ target }) => setName(target.value)}
      />
      <TextInput
        name={PROVIDER_PHONE}
        source="providerPhone"
        helperText={requiredHelperText}
        validate={isPhoneNumber()}
        label="Mobile"
        value={phone}
        onChange={({ target }) => setPhoneNumber(target.value)}
      />
      <TextInput
        name={PROVIDER_EMAIL}
        source="providerEmail"
        label="Email"
        helperText={requiredHelperText}
        validate={isEmail()}
        value={email}
        onChange={({ target }) => setEmail(target.value)}
      />
      <TextInput
        name={PROVIDER_NPI}
        source="providerNPI"
        helperText={requiredHelperText}
        label="NPI"
        value={npi}
        validate={isNpi()}
        onChange={({ target }) => setNPI(target.value)}
      />
    </FormTab>
  );
};

const PatientTab = ({
  setFirstName,
  setLastName,
  setEmail,
  setPhoneNumber,
  setBirthDate,
  firstName,
  lastName,
  phone,
  email,
  birthDate,
  toAppointmentTab,
  buttonClassName,
  ...props
}) => (
  <FormTab {...props} label="patient">
    <Button
      className={buttonClassName}
      label="remove"
      onClick={() => {
        toAppointmentTab();
        setFirstName('');
        setLastName('');
      }}
    >
      <IconCancel />
    </Button>
    <TextInput
      source="patientFirstName"
      label="First Name"
      validate={isName()}
      name={PATIENT_FIRST_NAME}
      helperText={requiredHelperText}
      value={firstName}
      onChange={({ target }) => setFirstName(target.value)}
    />
    <TextInput
      source="patientLastName"
      label="Last Name"
      name={PATIENT_LAST_NAME}
      helperText={requiredHelperText}
      validate={isName()}
      value={lastName}
      onChange={({ target }) => setLastName(target.value)}
    />
    <DateInput
      name={PATIENT_BIRTH_DATE}
      source="patientBirthDate"
      label="Date of Birth"
      validate={required()}
      helperText={requiredHelperText}
      value={birthDate}
      onChange={({ target }) => setBirthDate(target.value)}
    />
    <TextInput
      name={PATIENT_PHONE}
      source="patientPhone"
      label="Mobile"
      helperText={requiredHelperText}
      validate={isPhoneNumber()}
      value={phone}
      onChange={({ target }) => setPhoneNumber(target.value)}
    />
    <TextInput
      name={PATIENT_EMAIL}
      source="patientEmail"
      label="Email"
      type="email"
      helperText={optionalHelperText}
      validate={isOptional(isEmail())}
      value={email}
      onChange={({ target }) => setEmail(target.value)}
    />
  </FormTab>
);

const useStateAndField = (field, state) => {
  const [stateValue, setState] = useState(state);
  const {
    input: { onChange },
  } = useField(field);
  return [
    stateValue,
    (value, id) => {
      onChange(id || value);
      setState(value);
    },
  ];
};

function TabbedAppointmentForm({ practiceId, goBack, ...props }) {
  const [patientFirstName, setPatientFirstName] = useStateAndField(PATIENT_FIRST_NAME, '');
  const [patientLastName, setPatientLastName] = useStateAndField(PATIENT_LAST_NAME, '');
  const [providerName, setProviderName] = useStateAndField(PROVIDER_NAME, '');
  const [patientPhone, setPatientPhone] = useStateAndField(PATIENT_PHONE, '');
  const [patientBirthDate, setPatientBirthDate] = useStateAndField(PATIENT_BIRTH_DATE);
  const [patientEmail, setPatientEmail] = useStateAndField(PATIENT_EMAIL, '');
  const [providerEmail, setProviderEmail] = useStateAndField(PROVIDER_EMAIL, '');
  const [providerPhone, setProviderPhone] = useStateAndField(PROVIDER_PHONE, '');
  const [providerNPI, setProviderNPI] = useStateAndField(PROVIDER_NPI, '');
  const [providerTimezone, setProviderTimezone] = useStateAndField(
    PROVIDER_TIME_ZONE,
    DEFAULT_TIME_ZONE
  );
  const [appointmentTimezone, setAppointmentTimezone] = useStateAndField(
    APPOINTMENT_TIMEZONE,
    BROWSER_TIMEZONE
  );
  const [patientId, setPatientId] = useState();
  const [providerId, setProviderId] = useState();
  const [patientModalOpen, setPatientModalOpen] = useState(false);
  const [providerModalOpen, setProviderModalOpen] = useState(false);
  const classes = useStyles();
  const setPatientName = name => {
    const { firstName, lastName } = parseName(name);
    setPatientFirstName(firstName);
    setPatientLastName(lastName);
  };
  return (
    <TabbedForm {...props} submitOnEnter={false}>
      <FormTab label="appointment" default>
        <PatientModal
          group="patient-modal"
          showDialog={patientModalOpen}
          closeDialog={() => setPatientModalOpen(false)}
          firstName={patientFirstName}
          lastName={patientLastName}
          setFirstName={setPatientFirstName}
          setLastName={setPatientLastName}
          setPhoneNumber={setPatientPhone}
          setEmail={setPatientEmail}
          setDateOfBirth={setPatientBirthDate}
        />
        <ProviderModal
          id="provider-modal"
          key="provider-modal"
          showDialog={providerModalOpen}
          closeDialog={() => setProviderModalOpen(false)}
          name={providerName}
          timezone={providerTimezone}
          npi={providerNPI}
          setName={setProviderName}
          setNPI={setProviderNPI}
          setPhoneNumber={setProviderPhone}
          setEmail={setProviderEmail}
          setTimezone={setProviderTimezone}
        />
        <ReferenceInput
          filter={{ 'practices.id': `eq||${practiceId}` }}
          source="providerId"
          reference="providers"
          perPage={100}
        >
          <NameInputField
            placeholder="Provider..."
            name={PROVIDER_ID}
            setSelected={setProviderId}
            selected={providerId}
            userName={providerName}
            validate={required()}
            helperText={requiredHelperText}
            onCreate={name => {
              setProviderModalOpen(true);
              setProviderName(name);
            }}
          />
        </ReferenceInput>
        <ReferenceInput
          filter={{ practiceId: `eq||${practiceId}` }}
          source="memberId"
          reference="members"
          perPage={100}
        >
          <NameInputField
            name={PATIENT_ID}
            placeholder="Patient..."
            setSelected={setPatientId}
            selected={patientId}
            validate={required()}
            helperText={requiredHelperText}
            userName={
              patientFirstName
                ? fullNameRenderer({
                    firstName: patientFirstName,
                    lastName: patientLastName,
                  })
                : undefined
            }
            onCreate={name => {
              setPatientModalOpen(true);
              setPatientName(name);
            }}
          />
        </ReferenceInput>
        <DateTimeInput
          label="Appointment Time"
          source="time"
          helperText={requiredHelperText}
          validate={required()}
          minutesStep={15}
        />
        <TimeZoneDropDown
          label="Timezone"
          name={APPOINTMENT_TIMEZONE}
          defaultValue={BROWSER_TIMEZONE}
          value={appointmentTimezone}
          validate={required()}
          onChange={({ target }) => setAppointmentTimezone(target.value)}
        />
      </FormTab>
      {providerName && (
        <ProviderTab
          buttonClassName={classes.removeButton}
          setName={setProviderName}
          setEmail={setProviderEmail}
          setPhoneNumber={setProviderPhone}
          setTimezone={setProviderTimezone}
          setNPI={setProviderNPI}
          timezone={providerTimezone}
          npi={providerNPI}
          name={providerName}
          phone={providerPhone}
          email={providerEmail}
          toAppointmentTab={goBack}
        />
      )}
      {patientFirstName && (
        <PatientTab
          buttonClassName={classes.removeButton}
          setFirstName={setPatientFirstName}
          setLastName={setPatientLastName}
          setEmail={setPatientEmail}
          setPhoneNumber={setPatientPhone}
          setBirthDate={setPatientBirthDate}
          firstName={patientFirstName}
          lastName={patientLastName}
          email={patientEmail}
          phone={patientPhone}
          birthDate={patientBirthDate}
          toAppointmentTab={goBack}
        />
      )}
    </TabbedForm>
  );
}

export function AppointmentCreationForm({ goBack, record, version, practiceId, ...props }) {
  const submit = (data, ...args) => {
    const { time, appointmentTimezone, ...rest } = data;
    const convertedTime = moment(time).tz(appointmentTimezone, true);
    const formatted = formatFormOutput({
      ...rest,
      practiceId,
      time: convertedTime,
    });
    props.save(sanitizeEmptyValues(record, formatted), ...args);
  };
  return (
    <Form
      initialValues={record}
      onSubmit={submit}
      mutators={{ ...arrayMutators }} // necessary for ArrayInput
      subscription={defaultSubscription} // don't redraw entire form each time one field changes
      key={version} // support for refresh button
      keepDirtyOnReinitialize
      render={formProps => {
        return (
          <TabbedAppointmentForm
            {...formProps}
            practiceId={practiceId}
            save={submit}
            goBack={goBack}
            redirect="edit"
          />
        );
      }}
    />
  );
}

function BaseCreate(props) {
  const { practiceId, dispatch, ...rest } = props;
  const classes = useStyles();
  return (
    <Create className={classes.allowOverflow} {...rest}>
      <AppointmentCreationForm goBack={rest.history.goBack} practiceId={practiceId} />
    </Create>
  );
}

const mapStateToProps = state => ({
  practiceId: state.practiceId,
});

export const AppointmentCreate = connect(mapStateToProps, undefined)(BaseCreate);
