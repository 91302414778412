import nestProvider from './lib/nestProvider';
import auth from './lib/auth';

const apiUrl = process.env.REACT_APP_API_URL;

// returns a Headers object, cf. https://developer.mozilla.org/en-US/docs/Web/API/Headers/Headers
const getHeaders = () => {
  const token = auth.getIdToken();
  if (!token) return undefined;
  // Not sure if this is exposed globally...
  // eslint-disable-next-line no-undef
  return new Headers({
    authorization: `Bearer ${auth.getIdToken()}`,
  });
};

const dataProvider = nestProvider(apiUrl, getHeaders);

export default dataProvider;
