import React, { Component } from 'react';
import { Button, TextInput } from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { TimeZoneDropDown } from './TimeZoneDropDown';
import { optionalHelperText, requiredHelperText } from '../util/util';

class ProviderModal extends Component {
  constructor(props) {
    super(props);
    const { timezone } = this.props;
    /* eslint-disable react/no-unused-state */
    this.state = {
      error: false,
      showDialog: false,
      name: '',
      phone: '',
      email: '',
      npi: '',
      timezone,
    };
    /* eslint-enable */
  }

  handleClick = () => {
    const {
      setName,
      setPhoneNumber,
      setEmail,
      setTimezone,
      closeDialog,
      name: nameInput,
      setNPI,
    } = this.props;
    const { name, phone, email, timezone, npi } = this.state;
    setName(name.length ? name : nameInput);
    setPhoneNumber(phone);
    setTimezone(timezone);
    setEmail(email);
    setNPI(npi);
    closeDialog();
  };

  handleCloseClick = () => {
    const { setName, setPhoneNumber, setEmail, closeDialog, setTimezone, setNPI } = this.props;
    setName('');
    setPhoneNumber('');
    setEmail('');
    setTimezone(null);
    setNPI('');
    closeDialog();
  };

  render() {
    const { showDialog, name: nameInput } = this.props;
    const { npi, name, phone, email, timezone } = this.state;
    return (
      <>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          disableEnforceFocus
          aria-label="Create post"
        >
          <DialogTitle>Provider</DialogTitle>
          <DialogContent>
            <TimeZoneDropDown
              label="Time Zone"
              name="provider-modal-timezone"
              onChange={({ target }) => this.setState({ timezone: target.value })}
              helperText={requiredHelperText}
              value={timezone}
            />
            <TextInput
              label="Name"
              name="provider-name-modal"
              helperText={requiredHelperText}
              value={name.length ? name : nameInput}
              onChange={({ target }) => this.setState({ name: target.value })}
            />
            <TextInput
              label="Mobile"
              name="provider-phone-modal"
              helperText={requiredHelperText}
              value={phone}
              onChange={({ target }) => this.setState({ phone: target.value })}
            />
            <TextInput
              label="Email"
              type="email"
              name="provider-email-modal"
              value={email}
              helperText={requiredHelperText}
              onChange={({ target }) => this.setState({ email: target.value })}
            />
            <TextInput
              label="NPI"
              name="provider-npi"
              value={npi}
              helperText={requiredHelperText}
              onChange={({ target }) => this.setState({ npi: target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClick} label="ra.action.create">
              <IconContentAdd />
            </Button>
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default ProviderModal;
