import Joi from '@hapi/joi';
import { states } from './util';
import { isNpi, isPhoneNumber, isTin } from './validators';

const validNPI = isNpi();
const validPhoneNumber = isPhoneNumber();
const validTIN = isTin();

const customJoi = Joi.extend({
  base: Joi.string(),
  name: 'npi',
  language: {
    formatted: 'must be a ten digit number',
  },
  rules: [
    {
      name: 'formatted',
      validate(params, value, state, options) {
        const isInvalid = validNPI(value);
        if (isInvalid) {
          return this.createError('npi.formatted', { v: value }, state, options);
        }
        return value;
      },
    },
  ],
})
  .extend({
    base: Joi.string(),
    name: 'phoneNumber',
    language: {
      formatted: 'must be a valid phone number with a "000-000-0000" format',
    },
    rules: [
      {
        name: 'formatted',
        validate(params, value, state, options) {
          const isInvalid = validPhoneNumber(value);
          if (isInvalid) {
            return this.createError('phoneNumber.formatted', { v: value }, state, options);
          }
          return value;
        },
      },
    ],
  })
  .extend({
    base: Joi.string(),
    name: 'tin',
    language: {
      formatted: 'must be numberic with a "000-00-0000" format',
    },
    rules: [
      {
        name: 'formatted',
        validate(params, value, state, options) {
          const isInvalid = validTIN(value);
          if (isInvalid) {
            return this.createError('tin.formatted', { v: value }, state, options);
          }
          return value;
        },
      },
    ],
  });

const schemaObj = {
  firstName: customJoi.string().required().label('First Name'),
  lastName: customJoi.string().required().label('Last Name'),
  email: customJoi.string().email().required().label('Admin Email Address'),
  password: customJoi
    .string()
    .min(8)
    .regex(
      /^(?=(.*[a-z].*)?$)(?=(.*[A-Z].*)?$)(?=(.*[0-9].*)?$)(?=(.*[!@#$%^&*].*)?$)[a-zA-Z0-9!@#$%^&*]+$/
    )
    .required()
    .label('Admin Password')
    .error(
      () =>
        '"Admin Password" must be at least 8 characters long and must contain at least one lowercase (a-z), upper case (A-Z), number (0-9), and special character (!@#$%^&*)'
    ),
  phone: customJoi.phoneNumber().formatted().required().label('Scheduler Phone Number'),
  timezone: customJoi.string().required().label('Timezone'),
  isHealthcareProvider: customJoi.boolean(),
  npi: customJoi.npi().formatted().required().label('Provider NPI'),
  clientName: customJoi.string().required().label('Practice Name'),
  tin: customJoi.tin().formatted().required().label('Practice Tin'),
  practiceTimezone: customJoi.string().required().label('Timezone'),
  address: customJoi.string().required().label('Address'),
  city: customJoi.string().required().label('City'),
  state: customJoi.string().valid(Object.keys(states)).required().label('State'),
  zipcode: customJoi
    .string()
    .regex(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)
    .required()
    .label('Postal Code')
    .error(() => '"Postal Code" must be a valid US postal code'),
  contactEmail: customJoi.string().email().required().label('Contact Email'),
  schedulingPhone: customJoi.phoneNumber().formatted().required().label('Practice Phone Number'),
  techSupportPhone: customJoi
    .phoneNumber()
    .formatted()
    .required()
    .label('Tech Support Phone Number'),
  practiceShortName: customJoi.string().max(15).required().label('Practice Short Name'),
  practiceNpi: customJoi.npi().formatted().required().label('Practice NPI'),
  acceptedLegalTerms: customJoi.boolean().required(),
};

export default schemaObj;
