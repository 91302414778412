import React from "react";
import queryString from 'query-string';
import EmptyState from "../EmptyState";
import Button from '@material-ui/core/Button';
import auth from '../../lib/auth';

import { ReactComponent as NotFoundIllustration } from "../../assets/img/not-found.svg";

const AuthenticationError = (props) => {
  let { error, description } = queryString.parse(props.location.search);

  const submit = e => {
    e.preventDefault();
    auth.signIn();
  };

  return (
    <EmptyState
      image={<NotFoundIllustration />}
      title={error === 'unauthorized' ? 'Please Verify your e-mail' : 'Error Authenticating' }
      description={description || 'Contact administrator'}
      button={
        <Button key="button" variant="contained" color="primary" onClick={submit}>Login</Button>
      }
    />
  );
}

export default AuthenticationError;
