/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Create,
  List,
  Datagrid,
  DateInput,
  TextField,
  TextInput,
  EmailField,
  SimpleForm,
} from 'react-admin';
import { connect } from 'react-redux';

import { BirthDateField, optionalHelperText, requiredHelperText } from '../util/util';
import Edit from './PessimisticEdit';
import { isEmail, isName, isOptional, isPhoneNumber, required } from '../util/validators';

import WithPracticeToolbar from './overrides/WithPracticeToolbar';

export const BaseList = ({ practiceId, dispatch, ...props }) => (
  <List
    filter={{
      ...(practiceId ? { practiceId: `eq||${practiceId}` } : {}),
    }}
    title="Patients"
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <BirthDateField label="Date of Birth" source="birthDate" />
      <TextField source="phone" />
      <TextField label="Mobile" source="phone" />
      <EmailField source="email" />
    </Datagrid>
  </List>
);

const mapStateToProps = state => ({
  practiceId: state.practiceId,
});

export const MemberList = connect(mapStateToProps, undefined)(BaseList);

export const MemberCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<WithPracticeToolbar />}>
      <TextInput helperText={requiredHelperText} validate={isName()} source="firstName" />
      <TextInput helperText={requiredHelperText} validate={isName()} source="lastName" />
      <DateInput
        helperText={requiredHelperText}
        validate={required()}
        label="Date of Birth"
        source="birthDate"
      />
      <TextInput
        helperText={requiredHelperText}
        validate={isPhoneNumber()}
        label="Mobile"
        source="phone"
      />
      <TextInput
        helperText={optionalHelperText}
        validate={isOptional(isEmail())}
        source="email"
        type="email"
      />
    </SimpleForm>
  </Create>
);

export const MemberEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput helperText={requiredHelperText} validate={required()} source="id" />
      <TextInput helperText={requiredHelperText} validate={isName()} source="firstName" />
      <TextInput helperText={requiredHelperText} validate={isName()} source="lastName" />
      <DateInput
        helperText={requiredHelperText}
        validate={required()}
        label="Date of Birth"
        source="birthDate"
      />
      <TextInput
        helperText={requiredHelperText}
        validate={isPhoneNumber()}
        label="Mobile"
        source="phone"
      />
      <TextInput
        helperText={optionalHelperText}
        validate={isOptional(isEmail())}
        source="email"
        type="email"
      />
    </SimpleForm>
  </Edit>
);
