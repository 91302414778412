import React from 'react';
import { AppBar, Container, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { get } from 'lodash';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(2),
  },
}));

export default connect(state => ({
  accountEmail: get(state, 'router.location.state.accountEmail'),
}))(({ accountEmail }) => {
  const classes = useStyles();

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <img
            alt="Episource Logo"
            className={classes.logo}
            src={`${process.env.PUBLIC_URL}/logo@2x-1-300x71.png`}
          />
          <Typography variant="h5">TeleHealth Sign Up Successful</Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" className={classes.container}>
        <div>
          <div>
            <Typography variant="h6">Please verify your email!</Typography>
          </div>
          <div>
            We&apos;ve sent a confirmation email to
            <br />
            <strong>{accountEmail}</strong>
          </div>
        </div>
        <div>
          <ul>
            <li>
              If the confirmation email has not arrived within a minute or two, please check your
              spam folder.
            </li>
            <li>
              If you are still having problems verifying your email please contact{' '}
              <a href="mailto:help@episource.com">help@episource.com</a>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  );
});
