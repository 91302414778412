import React, { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { SaveButton } from 'react-admin';
import { connect } from 'react-redux';

const PracticeSaveButton = ({
  handleSubmitWithRedirect,
  redirect,
  many,
  practiceId,
  dispatch,
  ...props
}) => {
  const form = useForm();
  const handleClick = useCallback(() => {
    // change the practice field value
    if (many) {
      form.change('practiceIds', [practiceId]);
    } else {
      form.change('practiceId', practiceId);
    }
    handleSubmitWithRedirect(redirect);
  }, [form]);
  // override handleSubmitWithRedirect with custom logic
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

const mapStateToProps = state => ({
  practiceId: state.practiceId,
});

export default connect(mapStateToProps, undefined)(PracticeSaveButton);
