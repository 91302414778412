import { Button } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { copyToClipboard } from '../util/util';

const useStyles = makeStyles({
  copyButtonWidth: {
    width: '180px',
  },
});

export const CopyButton = ({ record, basePath, className, ...props }) => {
  const classes = useStyles();
  return (
    <Button
      {...props}
      className={`${className} ${classes.copyButtonWidth}`}
      onClick={() => copyToClipboard(record.link)}
    >
      Copy to clipboard
    </Button>
  );
};

export default CopyButton;
