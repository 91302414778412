import React from 'react';
import { TextField } from 'react-admin';
import moment from 'moment-timezone';

export const requiredHelperText = 'Required';
export const optionalHelperText = 'Optional';

export const getAllTimezones = countries =>
  countries.flatMap(country => moment.tz.zonesForCountry(country));

export const fullNameRenderer = choice => `${choice.firstName} ${choice.lastName}`;

export const FullNameField = ({ record = {} }) => (
  <span>
    {record.firstName} {record.lastName}
  </span>
);

export const ReadableDateField = ({ className, record = {} }) => {
  const tz = moment.tz.guess();
  return <span className={className}>{moment.tz(record.time, tz).format('LLL')}</span>;
};

export const parseName = name => {
  let result = ['', ''];
  if (name) {
    result = name.length > 1 ? name.split(/\s/).filter(word => word !== '') : [name, ''];
  }
  const [firstName, lastName] = result;
  return { firstName, lastName };
};

export const copyToClipboard = str => {
  const temp = document.createElement('textarea');
  temp.value = str;
  temp.setAttribute('readonly', '');
  temp.style.position = 'absolute';
  temp.style.left = '-9999px';
  document.body.appendChild(temp);
  temp.select();
  document.execCommand('copy');
  document.body.removeChild(temp);
};

export const BirthDateField = ({ record, ...props }) => {
  const date = moment.utc(record.birthDate).format('L');
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TextField {...props} source="birthDate" record={{ birthDate: date }} />;
};

export const states = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};
