import React from 'react';
import { AppBar as BaseAppBar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import PracticeSwitcher from './PracticeSwitcher';
import { loadAuthState } from '../../store/localStorage';
import logo from './logo.png';

const useStyles = makeStyles({
  // IMPORTANT: layout breaks if you use certain MUI styles...
  // cf. https://github.com/marmelab/react-admin/issues/1782
  // the fix was to sync @material-ui/core and react-admin package versions
  // also cf. https://marmelab.com/react-admin/Theming.html#replacing-the-appbar
  // title: {
  //   flex: 1,
  //   textOverflow: "ellipsis",
  //   whiteSpace: "nowrap",
  //   overflow: "hidden",
  // },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const AppBar = props => {
  const classes = useStyles();
  const authState = loadAuthState();
  const appMetadata = authState && authState.idTokenPayload[process.env.REACT_APP_APP_META_KEY];

  return (
    <BaseAppBar {...props}>
      <div className={classes.container}>
        <span id="react-admin-title" />
        <img src={logo} height="20" alt="Logo" style={{ filter: 'invert(100%)' }} />
        {appMetadata && <PracticeSwitcher />}
      </div>
    </BaseAppBar>
  );
};

export default AppBar;
