import React, { Component } from 'react';
import { Button, TextInput, DateInput } from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { optionalHelperText, requiredHelperText } from '../util/util';

class PatientModal extends Component {
  constructor(props) {
    super(props);
    /* eslint-disable react/no-unused-state */
    this.state = {
      error: false,
      showDialog: false,
      dateOfBirth: null,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    };
    /* eslint-enable */
  }

  handleClick = () => {
    const {
      setFirstName,
      setLastName,
      setPhoneNumber,
      setEmail,
      setDateOfBirth,
      closeDialog,
      firstName: firstNameInput,
      lastName: lastNameInput,
    } = this.props;
    const { dateOfBirth, firstName, lastName, phone, email } = this.state;
    setFirstName(firstName.length ? firstName : firstNameInput);
    setLastName(lastName.length ? lastName : lastNameInput);
    setDateOfBirth(dateOfBirth);
    setPhoneNumber(phone);
    setEmail(email);
    closeDialog();
  };

  handleCloseClick = () => {
    const {
      setFirstName,
      setLastName,
      setPhoneNumber,
      setEmail,
      setDateOfBirth,
      closeDialog,
    } = this.props;
    setFirstName('');
    setLastName('');
    setDateOfBirth();
    setPhoneNumber('');
    setEmail('');
    closeDialog();
  };

  render() {
    const { showDialog, firstName: firstNameInput, lastName: lastNameInput } = this.props;
    const { dateOfBirth, firstName, lastName, phone, email } = this.state;

    return (
      <>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Create post"
        >
          <DialogTitle>Patient</DialogTitle>
          <DialogContent>
            <TextInput
              label="First name"
              name="patient-modal-first-name"
              helperText={requiredHelperText}
              value={firstName.length ? firstName : firstNameInput}
              onChange={({ target }) => this.setState({ firstName: target.value })}
            />
            <TextInput
              label="last name"
              name="patient-modal-last-name"
              helperText={requiredHelperText}
              value={lastName.length ? lastName : lastNameInput}
              onChange={({ target }) => this.setState({ lastName: target.value })}
            />
            <DateInput
              label="Bate of Birth"
              name="patient-modal-dob"
              value={dateOfBirth}
              helperText={requiredHelperText}
              onChange={({ target }) => this.setState({ dateOfBirth: target.value })}
            />
            <TextInput
              label="mobile"
              name="patient-modal-phone"
              helperText={requiredHelperText}
              value={phone}
              onChange={({ target }) => this.setState({ phone: target.value })}
            />
            <TextInput
              label="email"
              name="patient-modal-email"
              type="email"
              helperText={optionalHelperText}
              value={email}
              onChange={({ target }) => this.setState({ email: target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClick} label="ra.action.create">
              <IconContentAdd />
            </Button>
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default PatientModal;
