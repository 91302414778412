import React from 'react';
import { Toolbar } from 'react-admin';
import PracticeSaveButton from './PracticeSaveButton';

const WithPracticeToolbar = ({ many, ...props }) => (
  <Toolbar {...props}>
    <PracticeSaveButton many={many} label="Save" redirect="show" submitOnEnter />
  </Toolbar>
);

export default WithPracticeToolbar;
