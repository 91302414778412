import React, { useState } from 'react';
import { SelectInput } from 'react-admin';
import { getAllTimezones, requiredHelperText } from '../util/util';
import { required } from '../util/validators';

const countries = ['US'];

const timezones = getAllTimezones(countries).map((name, id) => ({ id, name }));
export const DEFAULT_TIME_ZONE = 'America/Los_Angeles';

export const TimeZoneDropDown = ({ onChange, value, defaultValue, record, ...props }) => {
  const rec = record == null ? {} : record;
  const defaultVal = rec.timezone || defaultValue;
  const [timezone, setTimezone] = useState(defaultVal);
  const internalChange = ({ target }) => setTimezone(target.value);
  return (
    <SelectInput
      {...props}
      optionText="name"
      optionValue="name"
      defaultValue={defaultVal}
      validate={required()}
      helperText={requiredHelperText}
      choices={timezones}
      onChange={onChange || internalChange}
      value={value || timezone}
    />
  );
};

export default TimeZoneDropDown;
