import React, { useState } from "react";
import { connect } from "react-redux";
import { CondOperator } from "@nestjsx/crud-request";
import {
  List,
  Show,
  Filter,
  Datagrid,
  DateField,
  FunctionField,
  TextField,
  TextInput,
  UrlField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  useAuthState,
  Loading,
} from 'react-admin';
import { DateTimeInput } from 'react-admin-date-inputs';
import { makeStyles } from '@material-ui/core/styles';
import {
  fullNameRenderer,
  FullNameField,
  ReadableDateField,
  requiredHelperText,
  BirthDateField,
} from '../../util/util';
import Edit from '../PessimisticEdit';
import { CopyButton } from '../CopyButton';
import { required } from '../../util/validators';

export * from './create';

const useStyles = makeStyles({
  copyButton: {
    left: '370px',
    position: 'relative',
    bottom: '35px',
  },
  copyButtonShow: {
    left: '250px',
    position: 'relative',
    bottom: '18px',
  },
  appointmentTime: {
    color: '#3f51b5',
  },
  confirmationLink: {
    position: 'relative',
    bottom: '6px',
    marginRight: '6px',
    display: 'inline',
  },
  authError: {
    marginTop: '35px',
  },
});

const ConfirmationCodeLink = ({ record }) => {
  const classes = useStyles();
  return (
    <a href={record.link} rel="noopener noreferrer" target="_blank">
      <p className={classes.confirmationLink}>{record.confirmationCode}</p>
      <span className="material-icons">launch</span>
    </a>
  );
};

const INCLUDED_HOURS = 2;
const PROVIDER_TABLE = 'provider';
const MEMBER_TABLE = 'member';
const TIME_FILTER_PARAM = 'time';
const PRACTICE_FILTER_PARAM = 'practiceId';
const FUTURE_APPOINTMENT = 'Future';
const PAST_APPOINTMENT = 'Past';
const ALL_APPOINTMENTS = 'All';

const appointmentFilterStartTime = () => {
  const date = new Date();
  date.setHours(-INCLUDED_HOURS);
  return date.toISOString();
};

const generateSearchQuery = (text) => text.length ? ({
  searchQuery: {
    $or: [
      { [`${PROVIDER_TABLE}.name`]: { [CondOperator.CONTAINS_LOW]: text } },
      { [`${MEMBER_TABLE}.firstName`]: { [CondOperator.CONTAINS_LOW]: text } },
      { [`${MEMBER_TABLE}.lastName`]: { [CondOperator.CONTAINS_LOW]: text } },
    ],
  },
}) : undefined;

const AppointmentFilter = ({ setTimeFilter, timeFilter, ...props }) => {
  const date = appointmentFilterStartTime();
  const [searchText, setSearchText] = useState();
  const timeFilters = {
    [FUTURE_APPOINTMENT]: `${CondOperator.GREATER_THAN_EQUALS}||${date}`,
    [PAST_APPOINTMENT]: `${CondOperator.LOWER_THAN_EQUALS}||${date}`,
  };
  const timeFilterOptions = [{ value: FUTURE_APPOINTMENT }, { value: PAST_APPOINTMENT }];
  return (
    <Filter {...props}>
       <TextInput
         label="Search"
         source="appointments"
         parse={generateSearchQuery}
         format={() => searchText}
         onChange={({ target }) => setSearchText(target.value)}
         alwaysOn
       />
      <ReferenceInput
        label="Patient"
        source="memberId"
        reference="members"
        /* {
          Temporary fix, should be using a <AutocompleteInput/> 
          to perform a search instead of just bringin all the elements
          Implementing that component causes filters to break (error 500 on the BE)
          due to some error in the queries (invalid reference to FROM-clause entry for table)
        } */
        perPage={100}
        allowEmpty
      >
        <SelectInput optionText={fullNameRenderer} />
      </ReferenceInput>
      <ReferenceInput
        label="Provider"
        source="providerId"
        reference="providers"
        perPage={100}
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        label="Appointment Time"
        optionText="value"
        optionValue="value"
        value={timeFilter || ALL_APPOINTMENTS}
        source={TIME_FILTER_PARAM}
        parse={filter => timeFilters[filter]}
        onChange={({ target }) => setTimeFilter(target.value || ALL_APPOINTMENTS)}
        choices={timeFilterOptions}
      />
    </Filter>
  );
};

const BaseList = ({ practiceId, dispatch, ...props }) => {
  const { loading, authenticated } = useAuthState();
  const [timeFilter, setTimeFilter] = useState();
  const classes = useStyles();

  if (loading) return <Loading />;
  if (!authenticated)
    return (
      <div className={classes.authError}>
        Could not authenticate. Please try to{' '}
        <a href={process.env.REACT_APP_LOGIN_URI}>login again</a>.
      </div>
    );

  return (
    <List
      {...props}
      filters={<AppointmentFilter timeFilter={timeFilter} setTimeFilter={setTimeFilter} />}
      filter={{
        ...(!timeFilter
          ? {
              [TIME_FILTER_PARAM]: `${CondOperator.GREATER_THAN_EQUALS}||${appointmentFilterStartTime()}`,
            }
          : {}),
        ...(practiceId ? { [PRACTICE_FILTER_PARAM]: `${CondOperator.EQUALS}||${practiceId}` } : {}),
      }}
      sort={{ field: 'time', order: 'ASC' }}
      perPage={30}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReadableDateField
          className={classes.appointmentTime}
          label="Appointment Time"
          source="time"
        />
        <ReferenceField label="Patient Name" source="memberId" reference="members">
          <FullNameField source="lastName" />
        </ReferenceField>
        <ReferenceField label="Date of Birth" source="memberId" reference="members">
          <BirthDateField />
        </ReferenceField>
        <ReferenceField label="Provider Name" source="providerId" reference="providers">
          <TextField source="name" />
        </ReferenceField>
        <ConfirmationCodeLink label="Launch Visit" />
      </Datagrid>
    </List>
  );
};

const mapStateToProps = state => ({
  practiceId: state.practiceId,
});

export const AppointmentList = connect(mapStateToProps, undefined)(BaseList);

export const AppointmentEdit = props => {
  const classes = useStyles();
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <ReferenceInput source="providerId" reference="providers">
          <SelectInput helperText={requiredHelperText} validate={required()} optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Patient" source="memberId" reference="members">
          <SelectInput
            helperText={requiredHelperText}
            validate={required()}
            optionText={fullNameRenderer}
          />
        </ReferenceInput>
        <DateTimeInput
          helperText={requiredHelperText}
          validate={required()}
          label="Appointment Time"
          source="time"
        />
        <TextField label="Confirmation Code" source="confirmationCode" />
        <UrlField
          className={classes.link}
          rel="noopener noreferrer"
          target="_blank"
          label="Visit Link"
          source="link"
        />
        <CopyButton className={classes.copyButton} />
      </SimpleForm>
    </Edit>
  );
};

export const AppointmentShow = props => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="providerId" reference="providers">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Patient" source="memberId" reference="members">
          <FunctionField
            render={record => (record ? `${record.firstName} ${record.lastName}` : null)}
          />
        </ReferenceField>
        <DateField source="time" showTime />
        <TextField label="Confirmation Code" source="confirmationCode" />
        <UrlField
          className={classes.link}
          rel="noopener noreferrer"
          target="_blank"
          label="Visit Link"
          source="link"
        />
        <CopyButton className={classes.copyButtonShow} />
      </SimpleShowLayout>
    </Show>
  );
};
